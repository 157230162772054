import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import CardArticle from '../components/card-article'
import CategoriesMenu from '../components/categories-menu'
import Pagination from '../components/pagination'
import Seo from '../components/seo'
import { StyledGrid } from '../components/styles'

const CategoryTemplate = ({ pageContext, data }) => {
  const seoTitle =
    pageContext.currentPage > 1
      ? `${data.prismicCat.data.titel} · Pagina ${pageContext.currentPage}`
      : data.prismicCat.data.titel

  return (
    <Layout>
      <Seo title={seoTitle} />

      <h1 className="font-gradient-blue">{data.prismicCat.data.titel}</h1>
      <CategoriesMenu />
      <StyledGrid columns="3">
        {data.allPrismicArticle.edges.map(({ node }) => (
          <CardArticle key={node.uid} node={node} />
        ))}
      </StyledGrid>
      <Pagination
        pageContext={pageContext}
        label={`Meer ${data.prismicCat.data.titel.toLowerCase()} publicaties`}
        linkPrefix={`/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/categorie`}
      />
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query CategoryQuery($uid: String!, $limit: Int!, $skip: Int!) {
    prismicCat(uid: { eq: $uid }) {
      data {
        titel
      }
    }

    allPrismicArticle(
      sort: { fields: data___publication_date, order: DESC }
      filter: { data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            ...cardOverviewDataFragment
          }
          tags
        }
      }
    }
  }
`
